/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
html{
    height: 100%;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: 'Inter', sans-serif !important;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
#root{
    height: 100%;
}
body {
	line-height: 1;
    height: 100%;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* CUSTOM GENERAL STYLE */
body {
	@apply text-secondaryDark;
}

h4, h5 {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 16px;
    margin-top: 0;
    display: block;
    line-height: normal;
    text-transform: none;
}
h4{
	font-size: 20px;
}
input[type="checkbox"] ~ span:after{
    top: 3px;
    width: 7px;
}
.download-qrcode > a > span{
	display:flex;
	align-items: center;
}
.referral-state-PENDING{
	color: rgb(192, 192, 108);
}
.referral-state-APPROVED{
	color: rgb(82, 179, 119);
}
.referral-state-DENIED{
	color: rgb(179, 82, 87);
}

.rdw-editor-toolbar, .wrapperRichText{
	z-index: 0 !important;
}

.table-dma td.MuiTableCell-body {
	@apply px-7 py-4;
}

.dma-form-switch{
	display: flex;
    align-items: center;
    flex-direction: row;
}